class Analytics {
  constructor({trackers, modules}) {
    this.verbose = false;
    this.trackers = [''];
    
    this.addTrackers(trackers);
    this.addModules(modules);
  }
  
  addTrackers(trackers) {
    Object.entries(trackers || []).forEach(([name, trackingId]) => this.addTracker(name, trackingId))
  }
  
  addTracker(name, trackingId) {
    if (!trackingId) { return; }
    
    this.trackers.push(name);
  }
  
  addModules(modules) {
    Object.entries(modules || {}).forEach(([name, config]) => this.addModule(name, config.cmd, config));
  }
  
  addModule(name, cmd, helpers) {
    let module = this[name] ||= (...args) => {this.push(cmd, ...args)};
    Object.entries(helpers).forEach(([name, helper]) => module[name] = helper);
  }
  
  push(cmd, ...args) {
    this.verbose && console.log(cmd, ...args);
  }
  
  event(category, action, value) {
    this.push('_trackEvent', category, action, value);
  }
}

export let analytics = new Analytics({
  modules: {
    event: {
      cmd: '_trackEvent',
      
      findAction: function(el, event, def) {
        if (el.matches('input[type="checkbox"]')) { return def.replace('toggle', el.checked ? 'checked' : 'unchecked'); }
        if (el.matches('label')) { return analytics.event.findAction(el.control, event, def); }
        return def;
      },
      
      findId: function(el) {
        return el.dataset?.analyticsId || el.id;
      },
      
      ajax: function(el, eventName) {
        let [category, action] = el.dataset.ajaxAnalytics.split('.');
        let id = el.dataset.ajaxAnalyticsId || el.id;
    
        analytics.event(category || this.tagName.toLowerCase(), (action || 'ajax-' + eventName).replace('%', eventName), id);
      }
    }
  }
});

window.analytics = analytics;

//------ default analytics for common events
window.addEventListener('DOMContentLoaded', () => {
  analytics.verbose = document.body.parentNode.classList.contains('development-area');
  
  $(document).on('shown.bs.modal hide.bs.modal', '.modal', (event) => {
    analytics.event('modals', event.type.split('.')[0], analytics.event.findId(event.target));
  }).on('shown.recruit.hoverboard hide.recruit.hoverboard', '.hoverboard', (event) => {
    let target = event.currentTarget;
    analytics.event('hoverboards', event.type.split('.')[0], analytics.event.findId(target));
  }).on('shown.bs.tooltip hide.bs.tooltip', (event) => {
    let target = $(event.target);
    analytics.event('tooltips', event.type.split('.')[0], target.dataset?.analyticsId || $(target).data('original-title').split(" ").slice(0,5).join("-").toLowerCase());
  }).on('did-change.telescope', '.library', (event, telescope, scopes) => {
    let target = event.target;
    target.matches('.library') && analytics.event('library', 'show-shelf', scopes.rawValue);
  }).on("ajax:success", '[data-ajax-analytics]', (event) => {
    analytics.event.ajax(event.currentTarget, 'success');
  }).on("ajax:error", '[data-ajax-analytics]', (event) => {
    analytics.event.ajax(event.currentTarget, 'failure');
  }).on('ajax:aborted:file', '[data-ajax-analytics]', (event, elements) => {
    analytics.event.ajax(event.currentTarget, 'file-fallback');
  }).on('click', '*[data-analytics]', (event) => {
    let [category, defaultAction] = event.currentTarget.dataset.analytics.split('.');
    analytics.event(category, analytics.event.findAction(event.currentTarget, event, defaultAction), analytics.event.findId(event.currentTarget));
  });
})
