import {FormSerializer} from 'services/form_serializer';
import {Components} from 'components/components';

export class ListEditor {
  constructor(props) {
    this.name = props.name;
    this._list = props.list;
    this._template = props.template;
    this._itemName = props.itemName;
    this._itemSeparator = props.itemSeparator || "\n";
    
    if (this._list.length === 0) {
      return;
    }
    
    if (!this._list.attr('id') && $('html').is('.development-area')) {
      throw "List has no IDs; editor actions will not work.";
    }
    
    this._list.addClass('list-editor').data('listEditor', this);
    this._addActions(props.actions);
    this._enableSorting();
    this._addInternalListeners();
    props.data && this.data(props.data);
    
    props.onLoad && props.onLoad(this);
  }
  
  
  list() {
    return this._list;
  }
  
  template() {
    return this._template;
  }
  
  event(what, value) {
    analytics.event('list-editor', what, value || this._list.attr('analytics-id') || this._list.attr('id'));
  }
  
  // setup
  _triggerSelectorForAction(action) {
    var globalSelector = '.js-' + action + '-' + this._itemName.replace(/[^a-zA-Z_-]+/g, '-');
    var localSelector = '#' + (this._list.attr('id') || 'match-nothing') + ' *[data-behavior="' + action + '"]';
    return globalSelector + ', ' + localSelector;
  }
  
  _addActions(actions) {
    var editor = this;
    
    $.each(actions || {}, function(action, fn) {
      editor.addItemAction(action, function(event) {
        var $target = $(event.target);
        var $li = $target.closest('li');
        fn.bind(editor)(event, $li, $target);
      });
    });
  }
  
  _addInternalListeners() {
    var editor = this;
    var li = function(event) {return $(event.target).closest('li')};
    
    editor._addActions({
      'add-a': function(event) {editor.add(editor.itemTemplate($(event.target)))},
      'add-and-commit-a': function(event) {editor.commit(editor.add(editor.itemTemplate($(event.target))))},
      'edit': function(event, li) {editor.edit(li)},
      'commit': function(event, li) {editor.commit(li)},
      'cancel-editing': function(event, li) {editor.cancel(li)},
      'trash': function(event, li) {editor.trash(li)},
      'restore': function(event, li) {editor.restore(li)}
    });
    
    $(document).on('keyup change', editor._triggerSelectorForAction('validate'), function(event) {
      editor.enableDisableCommitButton(li(event));
    }).on('keydown', editor._triggerSelectorForAction('validate'), function(event) {
      var action = {
        13: 'commit', // return commits
        27: 'cancel', // escape cancels
        '': ''
      }[event.keyCode];
      
      if (action && !event.altKey && !event.shiftKey) {
        event.preventDefault();
        editor[action](li(event));
      }
    });
  }
  
  _setListOrders() {
    var editor = this;
    var list_order = 1;
    editor.listItems().each(function() {
      editor.model($(this)).list_order = list_order++;
      editor._pushToUi($(this), null, ['list_order']);
    });
  }
  
  _enableSorting() {
    new BusyBody({
      selector: "#" + this._list.attr('id'),
      added: (el) => {
        $(el).sortable({
          axis: "y",
          cursor: 'move',
          handle: '.grip',
          opacity: .75,
          placeholder: 'sorting-placeholder',
          update: () => this._setListOrders()
        });
      }
    });
  }
  
  _modelAttributes(el, item, attributes) {
    if (attributes) return attributes;
    var itemAttributes = Object.keys(item);
    var inputAttributes = $(el).find('[data-bind]').map(function(e) {
      return $(this).attr('data-bind');
    }).toArray();
    return itemAttributes.concat(inputAttributes).filter(function(item, ix, self) {
      return self.indexOf(item) === ix;
    });
  }
  
  _bindUi(el, item, attributes, fn) {
    item = item || this.model(el);
    attributes = this._modelAttributes(el, item, attributes);
    
    $.each(attributes, function(ix, field) {
      fn(field, item[field]);
    });
  }
  
  _pushToUi(el, item, attributes) {
    this._bindUi(el, item, attributes, function(field, value) {
      el.find('*[data-bind="' + field + '"]:not(:input)').text(value);
      el.find(':input[data-bind="' + field + '"]').val(value);
    });
  }
  
  _pullFromUi(el, item, attributes) {
    this._bindUi(el, item, attributes, function(field, oldValue) {
      var input = el.find(':input[data-bind="' + field + '"]:not([readonly])');
      if (input.length) {
        item[field] = input.val();
      }
    });
  }
  
  // list management
  listItems() {
    return this._list.find('li').not(this._template);
  }
  
  data(data) {
    var editor = this;
    
    if (arguments.length > 0) {
      editor.event('set-data', data ? data.length : 0);
      editor.clear();
      $.each(data, function() {
        editor.unedit(editor.add(this, {analytics: false}), {analytics: false});
      });
      return editor;
    } else {
      var retval = [];
      editor.listItems().each(function() {
        retval.push(editor.model($(this)));
      });
      return retval;
    }
  }
  
  clear() {
    this.listItems().remove();
    return this;
  }
  
  flashCurrentlyOpenItem(analyticsEvent, color) {
    var alreadyEditing = this._list.find('li.editing');
    if (alreadyEditing.length === 0) {
      return false;
    }

    this.event(analyticsEvent || 'flash-open-item', alreadyEditing.attr('id'));
    alreadyEditing.effect('highlight', {color: color || "#ffddaa"});
    return true;
  }
  
  clearValidationErrors() {
    this.listItems().removeClass('has-error');
  }
  
  itemTemplate(trigger) {
    var template = trigger && trigger.data(this._itemName + '-template');
    if (typeof(template) === 'string') {template = JSON.parse(template)}
    if (typeof(template) === 'function') {template = template(this)}
    return $.extend({}, template || {name: ''});
  }
  
  add(item, opts) {
    if (this.flashCurrentlyOpenItem('stopped-add')) {
      return;
    }

    var el = this._template.clone()
      .attr('id', this._itemName + "_" + (item.id || 'new_' + new Date().getTime()))
      .toggleClass('new-or-persisted', !!item.id)
      .removeClass('starts-hidden')
      .data('model', item);
    this._pushToUi(el, item);
    if (!opts || opts.analytics) { this.event('add', el.attr('id')); }
    this.trigger('will-add', [el, item]);
  
    this._list.append(el);
    this._setListOrders();
    this.edit(el, opts);
  
    return el;
  }
  
  // listeners
  trigger(event, args) {
    this._list.trigger(event + ".list-editor", args);
  }
  
  addItemAction(action, fn) {
    var editor = this;
    $(document).on('click', editor._triggerSelectorForAction(action), function(event) {
      event.preventDefault();
      fn(event);
    });
  }
  
  // item editing
  _newValue(el, attr, value) {
    var input = el.find(':input[data-bind="' + attr + '"]:not([readonly])');
    if (arguments.length > 2) {
      return input.val(value);
    } else {
      return input.val() || '';
    }
  }
  
  editor(item) {
    if ("function" === typeof item) {
      item = this.data().find(item);
    }
    return this.listItems().filter(function(ix, el) {return $(el).data('model') === item;});
  }
  
  model(el) {
    return el.data('model');
  }
  
  addValidationErrors(el) {
    return el && el.addClass('has-error');
  }
  
  enableDisableCommitButton(el) {
    var newName = this._newValue(el, 'name');
    var isBlank = newName.replace(/^\s+/, '').replace(/\s+$/, '').length === 0;
    
    $(this._triggerSelectorForAction('commit')).prop('disabled', isBlank);
  }
  
  edit(el, opts) {
    if (this.flashCurrentlyOpenItem('stopped-edit')) {
      return;
    }

    if (!opts || opts.analytics) { this.event('edit', el.attr('id')); }
    el.addClass('editing');
    this.enableDisableCommitButton(el);
    el.show();
    el.find('.focus-to-edit').focus();
    
    this.trigger('edit', el);
    
    return el;
  }
  
  unedit(el) {
    el.removeClass('editing');
    
    this.trigger('unedit', el);
    
    return el;
  }
  
  commit(el, opts) {
    var newName = this._newValue(el, 'name');
    if (newName === "") return el;

    if (!opts || opts.analytics) { this.event('commit', el.attr('id')); }
    var otherNames = newName.replace(/^\s+/, '').replace(/\s+$/, '').split(this._itemSeparator);
    newName = otherNames.splice(0, 1)[0];
    
    var model = this.model(el);
    this._pullFromUi(el, model);
    model.name = newName;
    this._pushToUi(el, model);
    el.addClass('new-or-persisted');
    this.unedit(el);
    this.trigger('commit', el);
    
    var editor = this;
    if (otherNames && otherNames.length > 0) {
      this.event('commit-multiple', otherNames.length + 1);
    }
    $.map(otherNames, function(otherName) {
      if (otherName.replace(/^\s+/, '').replace(/\s+$/, '')) {
        editor.commit(editor.add($.extend({}, model, {name: otherName}), {analytics: false}), {analytics: false});
      }
    });
    
    return el;
  }
  
  cancel(el) {
    var model = this.model(el);
    if (!model.id && !model.name) {
      this.event('cancel-and-abandon', el.attr('id'));
      return el.remove()
    }

    this.event('cancel', el.attr('id'));
    this._newValue(el, 'name', model.name);
    this.unedit(el);
    this.trigger('cancel', el);
    
    return el;
  }
  
  // item lifecycle
  trash(el) {
    this.event('trash', el.attr('id'));
      
    this.model(el)._destroy = true;
    el.addClass('trashed');
    this.trigger('trash', el);
    
    return el;
  }
  
  restore(el) {
    this.event('restore', el.attr('id'));
    
    delete this.model(el)._destroy;
    el.removeClass('trashed');
    this.trigger('restore', el);
    
    return el;
  }
}
  
export class ListEditorGroup {
  constructor(props) {
    this._root = props.root || props.form;
    this._form = props.form || this._root.closest('form');
    this._loadUrl = props.loadUrl || this._form.attr('data-load-action') || this._form.attr('action');
    this._saveUrl = props.saveUrl || this._form.attr('data-save-action') || this._form.attr('action');
    this._willSave = props.willSave || function(json) {return json};
    this._didSave = props.didSave || function(json) {return json};
    this._saveOptions = props.save || {};
    this._editorDefaults = props.editorDefaults || {};
    this._newItemTemplates = props.newItemTemplates;
    
    this._addSaveListener();
    this.addEditors(props.editors || []);
    
    this._form.length && this.load();
  }
  
  makeList(id, name) {
    var header = $('<h4>')
      .text(name)
      .append(' ')
      .append($('<button class="iconic-button btn-sm">')
        .addClass('js-add-a-' + id)
        .data(id + '-template', this._newItemTemplates[name] || this._newItemTemplates.default || {})
        .append(Components.iconic('plus'))
        .append('Add'))
      .appendTo(this._root);
    var list = $('<ol>')
      .attr('id', id)
      .addClass('list-unstyled')
      .appendTo(this._root);
    return list;
  }
  
  editor(name) {
    return this._editors[name];
  }
  
  addEditor(name, props) {
    return this._editors[name] = new ListEditor($.extend({}, this._editorDefaults, {
      list: props.id ? this.makeList(props.id, name) : null,
      itemName: (props.id || name || 'thing').toLowerCase().replace(/[^a-z0-9]+/g, '-')
    }, props));
  }
  
  addEditors(editors) {
    var group = this;
    
    group._editors = group._editors || {};
    $.each(editors, function(ix, props) {
      var editor = group.editor(props.name || ix);
      if (editor) {
        editor.data(props.data);
      } else {
        group.addEditor(props.name || ix, props);
      }
    });
  }
  
  anyEditor(fn) {
    var mapped = $.map(this._editors, fn);
    for (var ix in mapped) {
      if (mapped[ix]) { return true; }
    }
    return false;
  }
  
  eachEditor(fn) {
    return $.each(this._editors, fn);
  }
  
  _loadCallback() {
    return (json, status, xhr) => {
      new DynamicErrorArea(this._form[0]).clear();
      this._didSave(this, json, status, xhr);
    };
  }
  
  _failCallback() {
    var group = this;
    
    // add error highlights
    return function(xhr, errorname, error) {
      group.eachEditor(function(name, editor) {editor.clearValidationErrors()});
      
      var json = xhr.responseJSON || JSON.parse(xhr.responseText);
      group.eachEditor(function(name, editor) {
        var lookFor = (json.errors_by_editor || {})[name];
        var el = lookFor && editor.editor(function(i) {return i.list_order === lookFor.list_order});
        el && editor.addValidationErrors(el);
      });
    };
  }
  
  load(options) {
    return $.get($.extend({url: this._loadUrl}, options))
      .done(this._loadCallback());
  }
  
  save() {
    this._form.dispatchEvent(new CustomEvent('submit', {bubbles: true, cancelable: true}));
  }
  
  _addSaveListener() {
    var group = this;
    
    this._form.submit(function(event) {
      if (group.anyEditor(function(e) {return e.flashCurrentlyOpenItem('stopped-save')})) {
        Once.resetWithin(group._form);
        return false;
      }
      
      var formData = new FormSerializer(group._form[0]).object;
      var listData = $.map(group._editors, function(e) {return e.data()});
      
      ModalEditor.ajax(group._form, {
        url: group._saveUrl,
        method: 'put',
        data: group._willSave(formData, listData)
      })
        .done(group._loadCallback())
        .fail(group._failCallback());
  
      return false;
    });
  }
}
