export class Modal {
  constructor(options) {
    this.filled = false;
    this.options = {...this.defaultOptions, ...options};
  }
  
  get id() {return this.options.id}
  get title() {return this.options.title}
  
  get dialogElement() {return this.element.querySelector('.modal-dialog')}
  get titleElement() {return this.element.querySelector('.modal-title')}
  get subheaderElement() {return this.element.querySelector('.modal-sub-header')}
  get bodyElement() {return this.element.querySelector('.modal-body')}
  get footerElement() {return this.element.querySelector('.modal-footer')}
  get confirmButton() {return this.footerElement.querySelector('button.confirm')}
  
  get defaultOptions() {
    return {id: `dynamic-modal-${new Date().getTime()}`};
  }
  
  get element() {
    this._element = this._element || document.getElementById(this.id);
    if (this._element) { return this._element; }
    let subheaderEl = this.options.subheader ? Components.tag('div', {class: 'modal-sub-header'}) : null;

    this._element = Components.tag("div", 
      {class: "modal", tabindex: -1, role: "dialog"},
      {...this.options, title: null, appendTo: null, analyticsId: null, dialog: null, body: null, footer: null, buttons: null, cancel: null},
      (modal => document.querySelector('html.test-area') ? null : {class: "fade"}),
      Components.tag("div", {class: 'modal-dialog'},
        Components.tag("div", {class: 'modal-content'},
          Components.tag("div", {class: 'modal-header'},
            Components.tag("button", {type: "button", class: "close", 'data-dismiss': "modal", "aria-label": "close"},
              Components.tag("span", {"aria-hidden": true}, (s) => {s.innerHTML = "&times;"})
            ),
            Components.tag('h4', {class: 'modal-title'}, this.title),
            subheaderEl,
          ),
          Components.tag('div', {class: 'modal-body'}),
          Components.tag('div', {class: 'modal-footer'}),
        )
      )
    );
    
    this.attach();
    
    return this._element;
  }
  
  show() {
    this.filled || this.fill();
    $(this.element).modal('show');
    return this;
  }
  
  hide() {
    $(this.element).modal('hide');
    return this;
  }
  
  attach(to) {
    (to || this.options.appendTo || document.body).append(this.element);
    return this;
  }
  
  empty() {
    this.titleElement.innerHTML = '';
    if (this.subheaderElement) this.subheaderElement.innerHTML = '';
    this.bodyElement.innerHTML = '';
    this.footerElement.innerHTML = '';
    
    return this;
  }
  
  fill() {
    this.empty();
    this.element.setAttribute('data-analytics-id', this.options.analyticsId);
    
    [
      [this.dialogElement, this.options.dialog],
      [this.titleElement, this.options.title],
      [this.subheaderElement, this.options.subheader],
      [this.bodyElement, this.options.body],
      [this.footerElement, this.options.footer],
      [this.footerElement, this.options.buttons],
      [this.footerElement, this.cancelLink],
    ].forEach(([element, value]) => {
      if (element) Components.tag(element, value);
    });
    
    this.filled = true;
    return this;
  }
  
  get cancelLink() {
    let link = this._element?.querySelector('a.cancel');
    if (link) { return link; }
    
    if (this.options.cancel === false) { return; }
    
    return Components.tag('a',
      {href: '#', class: 'cancel', 'data-dismiss': "modal"},
      this.options.cancel ?? "Cancel");
  }
  
  static okButton(text=null, ...parts) {
    return Components.tag('button', {'data-dismiss': "modal"}, text || "OK", ...parts);
  }
  
  static confirmButton(options, ...parts) {
    return Components.tag('button',
      ...(options.confirmIcon ? [{class: 'iconic-button'}, Components.iconic(options.confirmIcon)] : []),
      options.confirm || 'Yes',
      options.confirmData,
      {class: `btn confirm ${options.confirmClass || 'btn-primary'}`},
      (options.dataDismiss != false ? {'data-dismiss': 'modal'} : null),
      ...parts,
    );
  }
  
  static confirm(options) {
    let modal = new Modal({
      title: 'Are you sure?',
      buttons: [Components.Modal.confirmButton(options)],
      class: 'confirmation',
      ...options,
      whenConfirmed: null,
      whenCancelled: null,
    }).fill();
    
    modal.confirmButton.addEventListener('click', (event) => {
      analytics.event('modal-confirmation', 'confirm', options.analyticsId || modal.id);
      options.whenConfirmed && options.whenConfirmed(modal);
    }, {once: true});
    
    modal.cancelLink.addEventListener('click', (event) => {
      analytics.event('modal-confirmation', 'cancel', options.analyticsId || modal.id);
      options.whenCancelled && options.whenCancelled(modal);
    }, {once: true});
    
    modal.show();
    return modal.callbacks;
  }
}
