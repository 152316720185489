import Rails from '@rails/ujs';

class AjaxTracking {
  constructor() {
    this.active = [];
    
    let trackComplete = document.querySelector('html').classList.contains('test-area');
    this.complete = trackComplete ? [] : null;
  }

  fetch(el, url, fetchOptions={}) {
    let entry = fetchOptions.logAs || Sherlock.stringPath(el);
    this.active.push(entry);
    this.injectCsrfHeader(fetchOptions);
    let promise = fetch(url, fetchOptions);
    promise.then(() => this.completed({detail: [{responseURL: url}]}, entry));
    return promise;
  }

  injectCsrfHeader(fetchOptions) {
    fetchOptions.headers ??= {};
    fetchOptions.headers['X-CSRF-Token'] ??= Rails.csrfToken();
    return fetchOptions;
  }
  
  started(event) {
    let entry = Sherlock.stringPath(event.target);
    this.active.push(entry);
    event.target.addEventListener('ajax:complete', event => this.completed(event, entry), {once: true});
  }
  
  completed(event, entry) {
    let ix = this.active.findIndex(o => o === entry);
    if (ix > -1) { this.active.splice(ix, 1); }
    this.complete?.push(`[${ix}] ${entry} -> ${event.detail[0].responseURL}`);
  }
  
  watch() {
    document.addEventListener('ajax:send', event => this.started(event));
  }
}

window.ajaxTracking = new AjaxTracking();
window.ajaxTracking.watch();
